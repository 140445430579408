import { useAtom, useAtomValue } from 'jotai';
import React, { useState, useRef, useEffect } from 'react';

import codeAtom from '../../jotai/atoms/code.js';
import sessionAtom from '../../jotai/atoms/session.js';
import quizService from '../../services/quiz.service.js';

const Timer = (props) => {
  const code = useAtomValue(codeAtom);
  const [session, setSession] = useAtom(sessionAtom);
  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef(null);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    // const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      // hours,
      minutes,
      seconds,
    };
  };

  const getDeadTime = (duration) => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + Number(duration));
    return deadline;
  };

  const formatDuration = () => {
    let { minutes, seconds } = getTimeRemaining(getDeadTime(props.duration));
    return (
      (minutes > 9 ? minutes : '0' + minutes) +
      ':' +
      (seconds > 9 ? seconds : '0' + seconds)
    );
  };

  // The state for our timer
  const [timer, setTimer] = useState(formatDuration());

  const startTimer = (e) => {
    // let { total, hours, minutes, seconds } = getTimeRemaining(e);
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        // (hours > 9 ? hours : '0' + hours) +
        //   ':' +
        (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  useEffect(() => {
    if (session.duration != null) clearTimer(getDeadTime(session.duration));
  }, [session.duration]);

  useEffect(() => {
    if (timer === '00:00') {
      if (Ref.current) clearInterval(Ref.current);
      const submitQuiz = async () => {
        await quizService.submitQuiz(code, {
          fullName: session.user.name,
          email: session.user.email,
          attemptedQuestions: session.questions
            .filter((question) => question.selected)
            .map(
              (question) =>
                question.selected && {
                  questionId: question.questionId,
                  selected: question.selected,
                }
            ),
        });
      };
      submitQuiz();
      setSession((session) => ({
        ...session,
        submitted: true,
        duration: 0,
      }));
    }
  }, [timer]);

  return <div style={{ textAlign: 'center', margin: 'auto' }}>{timer}</div>;
};

export default Timer;
